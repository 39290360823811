import React from "react";
import styled from "@emotion/styled";
import { Box, IconButton, Slider, SliderProps } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Pause } from "@mui/icons-material";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
`;

type Props = {
  audio: string;
};

export function AudioPlayer({ audio }: Props) {
  const [playing, setPlaying] = React.useState(false);
  const [position, setPosition] = React.useState(0);

  const [audioElement, setAudioElement] = React.useState<HTMLAudioElement | null>(null);

  const sliderRef = React.useRef<HTMLDivElement>(null);

  function changeCurrentTimeForTimeline(audioElement: HTMLAudioElement | null): SliderProps["onChange"] {
    return (e, v) => {
      if (audioElement && typeof v === "number") {
        audioElement.fastSeek(v);
      }
    };
  }

  React.useEffect(() => {
    if (!audioElement) return;

    audioElement.addEventListener("playing", () => setPlaying(true));

    let updateTimeout: NodeJS.Timeout | null = null;

    audioElement.addEventListener("timeupdate", () => {
      if (updateTimeout) return;

      updateTimeout = setTimeout(() => {
        setPosition(audioElement.currentTime);
        updateTimeout = null;
      }, 100);
    });

    audioElement.addEventListener("pause", () => setPlaying(false));
    audioElement.addEventListener("ended", () => setPlaying(false));
    audioElement.addEventListener("error", (e) => {
      setPlaying(false);
      console.error(e);
    });
  }, [audioElement]);

  return (
    <Container>
      <audio src={audio} style={{ display: "none" }} ref={setAudioElement} />
      <IconButton
        color="primary"
        style={{ marginRight: "20px" }}
        onClick={() => {
          playing ? audioElement?.pause() : audioElement?.play();
        }}
      >
        {playing ? <Pause /> : <PlayArrowIcon />}
      </IconButton>
      <Slider
        size="small"
        min={0}
        step={0.001}
        max={audioElement?.duration!}
        onChange={changeCurrentTimeForTimeline(audioElement)}
        value={position}
      />
    </Container>
  );
}
