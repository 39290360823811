import React from "react";
import styled from "@emotion/styled";
import useWebservice from "@livepix/components/hooks/useWebservice";
import { ProfileData, ProfileReward } from "@livepix/sdk-js/types/profile";
import ProfileButton from "@livepix/components/profile/ProfileButton";
import RewardConfirmation from "./RewardConfirmation";
import { PaymentIntent } from "@livepix/sdk-js/types/core";

import { Box, Typography, LinearProgress } from "@mui/material";

const Container = styled.div``;

const ConfirmationTitle = styled(Typography)`
  margin: 30px 0 10px;
  font-weight: bold;
  font-size: 30px;
`;

const ConfirmationMessage = styled(Typography)`
  margin: 0;
  font-size: 20px;
  font-weight: normal;
`;

const Proof = styled(Typography)`
  margin: 20px 0 0;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  word-break: break-all;

  span {
    display: block;
    text-transform: uppercase;
    font-weight: 400;
    color: #666;
  }
`;

type Props = {
  profile: ProfileData;
  payment: PaymentIntent;
  proof?: string;
  onReset?: () => void;
  onResize?: () => void;
};

export default function PaymentReceipt({ profile, payment, onReset, onResize, ...props }: Props) {
  const webservice = useWebservice();

  const [proof, setProof] = React.useState<string | undefined>(props.proof);
  const [rewards, setRewards] = React.useState<ProfileReward[]>();

  React.useEffect(() => {
    const interval = setInterval(() => {
      webservice
        .get<{ proof?: string; rewards: ProfileReward[] }>(
          `/products/receipt/${payment.product}/${payment.reference}/${payment.transitId}`,
        )
        .then(({ data: { proof, rewards } }) => {
          if (!proof) return;

          setProof(proof);
          setRewards(rewards);

          const hasPending = !rewards.every(
            (reward) => reward.status !== "GrantPending" || typeof reward.data.invite !== "undefined",
          );

          if (!hasPending) clearInterval(interval);
        })
        .catch(() => {});
    }, 5_000);

    return () => clearInterval(interval);
  }, [payment]);

  React.useEffect(() => {
    if (onResize) onResize();
  }, [proof, rewards]);

  if (!proof) {
    return (
      <Container>
        <ConfirmationTitle>Quase lá...</ConfirmationTitle>
        <ConfirmationMessage>Estamos confirmando seu pagamento, aguarde alguns segundos.</ConfirmationMessage>
        <Box height="100px" marginTop="20px">
          <LinearProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <ConfirmationTitle>Obrigado!</ConfirmationTitle>
      <ConfirmationMessage>
        Seu pagamento foi confirmado e sua mensagem será exibida na transmissão em instantes.
      </ConfirmationMessage>
      <Proof>
        <span>ID da Transação</span>
        {proof}
      </Proof>
      <RewardConfirmation rewards={rewards} />
      {onReset && (
        <Box marginTop="40px">
          <ProfileButton customcolor={profile.color} onClick={() => onReset()}>
            Voltar
          </ProfileButton>
        </Box>
      )}
    </Container>
  );
}
