import React from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { UserCampaign } from "@livepix/sdk-js/types/core";
import { Tooltip } from "@mui/material";

const Container = styled.div`
  padding: 10px;
  margin: 0 0 20px;
  border: 2px solid #1a2e59;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #f9f9f9;
  }
`;

const CampaignLabel = styled.p`
  margin: 0 0 7px;
  padding: 1px 6px 2px;
  width: fit-content;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: white;
  background: #6bab90;
`;

const CampaignName = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 20px;
  color: #1a2e59;
`;

const CampaignDescription = styled.p`
  margin: 0;
  font-size: 14px;
  color: #7e7e7e;

  svg {
    margin: 0 0 -3px 3px;
    font-size: 16px;
  }
`;

const CampaignAmount = styled.p`
  margin: 14px 0 5px;
  font-size: 30px;
  font-weight: bold;
  color: #6bab90;

  small {
    display: block;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 12px;
    color: #666;
  }
`;

const formatAmount = (amount: number) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(amount);

type Props = {
  username: string;
  campaign: UserCampaign;
};

const ProfileCampaign: React.FC<Props> = ({ username, campaign }) => {
  const router = useRouter();

  return (
    <Container onClick={() => router.push(`/campanhas/${campaign.campaign.slug}?partner=${username}`)}>
      <CampaignLabel>Campanha Beneficente</CampaignLabel>
      <CampaignName>{campaign.campaign.name}</CampaignName>
      <CampaignAmount>
        <small>valor arrecadado:</small>
        {formatAmount(campaign.campaign.amountCollected / 100)}
      </CampaignAmount>
      <Tooltip title="A porcentagem se refere ao valor líquido, depois de descontado a taxas de processamento e impostos.">
        <CampaignDescription>
          <b>{campaign.split}%</b> do valor doado será destinado a campanha.*
        </CampaignDescription>
      </Tooltip>
    </Container>
  );
};

export default ProfileCampaign;
