export const format = (amount: number, currency: string): string => {
  try {
    const currencyFormatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 8,
    });

    return currencyFormatter.format(amount);
  } catch (e) {
    const decimalFormatter = new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 8,
    });

    return `${currency} ${decimalFormatter.format(amount)}`;
  }
};

export const round = (amount: number, decimals?: number): number => {
  decimals ??= 2;

  const delta = 10 ** decimals;

  return Math.round((amount + Number.EPSILON) * delta) / delta;
};

export const toCents = (number: number): number => {
  return Math.round(number * 100);
};

export const fromCents = (number: number): number => {
  return round(number / 100, 2);
};

export default { format, round, toCents, fromCents };
