import React from "react";
import styled from "@emotion/styled";
import { Reward } from "@livepix/sdk-js/types/core";
import { ProfileData } from "@livepix/sdk-js/types/profile";
import ProfileButton from "@livepix/components/profile/ProfileButton";
import { useRouter } from "next/router";

const RewardTitle = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 26px;
`;

const RewardDescription = styled.p`
  flex: 1;
  margin: 10px 0 20px;
  font-size: 20px;
`;

const RewardAmount = styled.p`
  flex: 1;
  margin: 0;
  font-size: 22px;
  text-transform: uppercase;
  color: #666;
`;

type Props = {
  profile: ProfileData;
  reward: Reward;
};

const formatAmount = (amount: number) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(amount);

export default function RewardSlide({ profile, reward }: Props) {
  const router = useRouter();

  return (
    <>
      <RewardAmount>{formatAmount(reward.amount)} ou mais</RewardAmount>
      <RewardTitle>{reward.title}</RewardTitle>
      <RewardDescription>{reward.description}</RewardDescription>
      <ProfileButton
        customcolor={profile.color}
        onClick={async () => {
          if (reward.triggers.includes("subscription")) {
            if (reward.plans && reward.plans.length > 0) {
              await router.push(`/${profile.username}/assinatura?plan=${reward.plans.pop()}`);
            } else {
              await router.push(`/${profile.username}/assinatura?amount=${reward.amount}`);
            }
          } else {
            await router.push(`/${profile.username}?amount=${reward.amount}`);
          }
        }}
        disabled={reward.granted}
      >
        {reward.granted ? "Recompensa já recebida" : "Quero esta recompensa"}
      </ProfileButton>
    </>
  );
}
