import React from "react";
import styled from "@emotion/styled";
import { Card, CircularProgress, IconButton, Tooltip } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { ProfileData } from "@livepix/sdk-js/types/profile";
import ProfileButton from "@livepix/components/profile/ProfileButton";
import useWebservice from "@livepix/components/hooks/useWebservice";

const Description = styled.p`
  margin: 0;
  text-align: center;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VoicesContainer = styled.div`
  width: 100%;
  margin: 20px 0 20px;
  max-height: 300px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  overflow-x: hidden;

  @media (max-width: 800px) {
    max-height: 100%;
  }
`;

const VoiceContainer = styled(Card)`
  margin-bottom: 10px;
  border: none;
  cursor: pointer;

  &:hover {
    > div > img {
      transform: scale(1.1);
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const VoiceImage = styled.div<{ color: string; selected: boolean }>`
  display: flex;
  position: relative;
  border-width: 2px;
  border-radius: 5px;
  border-style: solid;
  border-color: ${({ color, selected }) => (selected ? color : "#f1f1f1")};
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
    transition: all 0.3s;
  }

  > button {
    position: absolute;
    bottom: -5px;
    right: -5px;

    svg {
      color: rgba(255, 255, 255, 0.7);
    }

    &:hover svg {
      color: #fff;
    }
  }
`;

const PlaySampleButton = styled(IconButton)``;

type CreatorVoice = {
  id: string;
  image: string;
  sample?: string;
  name?: string;
  price?: number;
};

type Props = {
  profile: ProfileData;
  onContinue: (voice: string) => void;
  onResize: () => void;
};

export default function SetupVoice({ profile, onContinue, onResize }: Props) {
  const webservice = useWebservice();

  const { data: voices, isLoading } = webservice.loadOnce<CreatorVoice[]>(`/profile/${profile.username}/ai-voices`);

  const [voice, setVoice] = React.useState<string>("default");

  React.useEffect(() => onResize(), [voices, voice]);

  const playVoiceSample = (voice: string) => {
    const audio = new Audio(voice);
    audio.play();
  };

  return (
    <>
      <Description>Escolha uma voz para ler sua mensagem:</Description>
      {isLoading && (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}
      <VoicesContainer>
        {voices?.map((model) => (
          <Tooltip title={model.name || undefined} placement="top" key={model.id} arrow>
            <VoiceContainer
              elevation={0}
              onClick={() => setVoice(model.id)}
              style={{ opacity: voice === "default" || voice === model.id ? 1 : 0.8 }}
            >
              <VoiceImage color={profile.color} selected={voice === model.id}>
                <img src={model.image || "/images/avatar.png"} alt="Ilustração da voz" onLoad={() => onResize()} />
                {model.sample && (
                  <PlaySampleButton onClick={() => playVoiceSample(model.sample!)}>
                    <PlayCircleIcon htmlColor="#fff" />
                  </PlaySampleButton>
                )}
              </VoiceImage>
            </VoiceContainer>
          </Tooltip>
        ))}
      </VoicesContainer>
      <ProfileButton customcolor={profile.color} onClick={() => onContinue(voice)}>
        Continuar
      </ProfileButton>
    </>
  );
}
