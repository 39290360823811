import React from "react";
import Head from "next/head";
import { AxiosError } from "axios";
import { GetServerSideProps } from "next";
import styled from "@emotion/styled";
import SwipeableViews from "react-swipeable-views";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TransactionSlide from "components/profile/TransactionSlide";
import RewardSlide from "components/profile/RewardSlide";
import { ProfileData } from "@livepix/sdk-js/types/profile";
import { User, Reward } from "@livepix/sdk-js/types/core";
import { isUsernameValid } from "@livepix/sdk-js/validation";
import useWebservice from "@livepix/components/hooks/useWebservice";
import webserviceClient from "lib/webserviceClient";
import ProfilePage from "components/profile/ProfilePage";
import SubscriptionPlanSlide from "components/profile/SubscriptionPlanSlide";

import { Alert, Snackbar, IconButton } from "@mui/material";
import Captcha, { ReCAPTCHA } from "@livepix/components/common/Captcha";

const SlidesContainer = styled.div`
  position: relative;
  width: 100%;
`;

const ArrowButton = styled(IconButton)<{ bgcolor: string }>`
  position: absolute;
  top: calc(50% - 20px);
  padding: 3px;
  background-color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);

  &:hover {
    background-color: ${(props) => props.bgcolor};
    color: white;
  }

  &:disabled {
    display: none;
  }
`;

const PreviousButton = styled(ArrowButton)`
  left: -36px;

  @media (max-width: 500px) {
    left: -26px;
  }
`;

const NextButton = styled(ArrowButton)`
  right: -36px;

  @media (max-width: 500px) {
    right: -26px;
  }
`;

const DotsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0;
`;

const Dot = styled.div<{ color: string; selected: boolean }>`
  width: 10px;
  height: 10px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.selected ? props.color : "#ccc")};
  cursor: pointer;
`;

const CarouselItem = styled.div`
  width: 100%;
  padding: 5px 5px 0;
`;

const Title = styled.p`
  margin: 0 0 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #666;
`;

type Props = {
  amount?: number;
  profile: ProfileData;
};

export const getServerSideProps: GetServerSideProps<Props> = async (context) => {
  if (!context.params?.username) return { notFound: true };

  const { username } = context.params;
  const { amount } = context.query;

  if (!username || typeof username !== "string" || !isUsernameValid(username)) return { notFound: true };

  try {
    const { data: profile } = await webserviceClient.get(`/profile/${(username as string).toLowerCase()}`);

    const props: Props = { profile };

    if (amount) props.amount = Number(amount);

    return { props };
  } catch (e) {
    if ((e as AxiosError).response?.status !== 404) {
      throw e;
    }

    return { notFound: true };
  }
};

export default function ProfileNextPage({ profile, amount }: Props) {
  const webservice = useWebservice();

  const [captcha, setCaptcha] = React.useState<ReCAPTCHA>();

  const { data: user } = webservice.loadOnce<User>("/auth/me");
  const { data: rewards } = webservice.loadOnce<Reward[]>(`/profile/${profile.username}/rewards`);

  const carouselRef = React.useRef<any>(null);

  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(true);

  const [carouselIndex, setCarouselIndex] = React.useState<number>(0);

  React.useEffect(() => {
    setLoading(false);
  }, []);

  const slides = React.useMemo(() => {
    const items = [];

    const products = profile.products;

    if (products.message.enabled || products["ai-voice-message"].enabled || products["audio-message"].enabled) {
      items.push(
        <CarouselItem key="transaction">
          <Title>Envie uma mensagem</Title>
          <TransactionSlide
            user={user}
            profile={profile}
            rewards={rewards || []}
            captcha={captcha}
            suggestedAmount={amount}
            onResize={() => {
              requestAnimationFrame(() => carouselRef.current?.updateHeight());
            }}
          />
        </CarouselItem>,
      );
    }

    const campaignRewards = rewards?.filter((reward) => reward.type === "campaign-coupon") || [];

    for (const reward of campaignRewards) {
      items.push(
        <CarouselItem key={reward.id}>
          <Title>Recompensa patrocinada</Title>
          <RewardSlide profile={profile} reward={reward} />
        </CarouselItem>,
      );
    }

    if (Array.isArray(profile.plans)) {
      for (const plan of profile.plans) {
        items.push(
          <CarouselItem key={plan.id}>
            <Title>Plano de assinatura</Title>
            <SubscriptionPlanSlide profile={profile} plan={plan} />
          </CarouselItem>,
        );
      }
    }

    if (Array.isArray(rewards)) {
      for (const reward of rewards.filter((reward) => reward.type !== "campaign-coupon")) {
        items.push(
          <CarouselItem key={reward.id}>
            <Title>Recompensa</Title>
            <RewardSlide profile={profile} reward={reward} />
          </CarouselItem>,
        );
      }
    }

    return items;
  }, [carouselRef, captcha, user, profile, rewards]);

  return (
    <>
      <Head>
        <title>{`${profile.name} - LivePix`}</title>
        <meta
          name="description"
          content={`Envie uma mensagem ou assinatura para ${profile.name} via PIX ou Cartão de Crédito!`}
        />
        <link rel="canonical" href={`https://livepix.gg/${profile.username.toLowerCase()}`} />
      </Head>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(undefined)}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
      <Captcha onReady={(instance) => setCaptcha(instance)} />
      <ProfilePage profile={profile} loading={loading}>
        <SlidesContainer>
          <PreviousButton
            bgcolor={profile.color}
            disabled={carouselIndex <= 0}
            onClick={() => setCarouselIndex((current) => current - 1)}
          >
            <KeyboardArrowLeftIcon />
          </PreviousButton>
          <SwipeableViews
            ref={carouselRef}
            color={profile.color}
            index={carouselIndex}
            onChangeIndex={(index: number) => setCarouselIndex(index)}
            animateHeight
          >
            {slides}
          </SwipeableViews>
          <NextButton
            bgcolor={profile.color}
            disabled={carouselIndex + 1 >= slides.length}
            onClick={() => setCarouselIndex((current) => current + 1)}
          >
            <KeyboardArrowRightIcon />
          </NextButton>
        </SlidesContainer>
        <DotsContainer>
          {slides.map((_, index) => (
            <Dot
              key={index}
              color={profile.color}
              selected={index === carouselIndex}
              onClick={() => setCarouselIndex(index)}
            />
          ))}
        </DotsContainer>
      </ProfilePage>
    </>
  );
}
