import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ProfileReward } from "@livepix/sdk-js/types/profile";
import { LinearProgress, Card, Link, Alert, Modal } from "@mui/material";

const Container = styled.div``;

const Title = styled.p`
  margin: 20px 0 0;
  text-transform: uppercase;
  font-weight: 500;
  color: #666;
`;

const RewardsContainer = styled.div`
  width: 100%;
  margin: 10px 0 20px;

  @media (max-width: 800px) {
    max-height: 100%;
  }
`;

const RewardContainer = styled(Card)`
  margin-bottom: 10px;
  background: #f9f9f9;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const RewardContent = styled.div`
  padding: 14px;
`;

const RewardActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  border-top: 1px solid #e6e6e6;

  .MuiLinearProgress-root {
    width: 100%;
  }

  button {
    margin-left: 10px;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;

const RewardType = styled.p`
  margin: 0;
  text-transform: uppercase;
  font-size: 16px;
  color: #666;
`;

const RewardTitle = styled.p`
  margin: 0;
  font-weight: bold;
`;

const RewardStatus = styled.p<{ error?: boolean; success?: boolean }>`
  margin: 0;
  line-height: 20px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;

  ${(props) =>
    props.error &&
    css`
      color: red;
    `}

  ${(props) =>
    props.success &&
    css`
      color: #6bab90;
    `}
`;

const MediaContainer = styled.div`
  width: calc(100% - 10px);
  margin: -12px 5px 0;

  img {
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
  }

  video {
    width: 100%;
  }
`;

const ImageModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
    outline: none;
  }
`;

const typeLabels: Record<string, string> = {
  "discord-role": "Cargo no Discord",
  "telegram-channel": "Grupo no Telegram",
  "campaign-coupon": "Código patrocinado",
  image: "Imagem",
  video: "Vídeo",
};

type Props = {
  rewards?: ProfileReward[];
};

export default function RewardConfirmation({ rewards }: Props) {
  const [imageOpen, setImageOpen] = React.useState(false);

  return (
    <Container>
      <Title>Recompensas</Title>
      <RewardsContainer>
        {rewards === undefined && <LinearProgress />}
        {rewards?.length === 0 && <Alert severity="info">Nenhuma recompensa concedida.</Alert>}
        {rewards?.map((reward) => (
          <RewardContainer elevation={0} key={reward.id}>
            <RewardContent>
              <RewardType>{typeLabels[reward.type]}</RewardType>
              <RewardTitle>{reward.title}</RewardTitle>
            </RewardContent>
            <RewardActions>
              {reward.status === "Ignored" && <RewardStatus>Recompensa ignorada</RewardStatus>}
              {reward.status === "Granted" && !["image", "video"].includes(reward.type) && (
                <RewardStatus success>Recompensa concedida</RewardStatus>
              )}
              {reward.status === "GrantError" && (
                <RewardStatus error>{reward.reason || "Não foi possível conceder."}</RewardStatus>
              )}
              {reward.status === "GrantPending" && typeof reward.data.invite === "undefined" && (
                <LinearProgress color="primary" />
              )}
              {reward.status === "Granted" && (
                <>
                  {typeof reward.data.invite !== "undefined" && (
                    <RewardStatus success>
                      <Link href={reward.data.invite} target="_blank">
                        {reward.data.invite}
                      </Link>
                    </RewardStatus>
                  )}
                </>
              )}
            </RewardActions>
            {typeof reward.data.source !== "undefined" && (
              <MediaContainer>
                {reward.type === "video" && (
                  <video key={new URL(reward.data.source).pathname} controls controlsList="nodownload">
                    <source src={reward.data.source} type="video/mp4" />
                  </video>
                )}
                {reward.type === "image" && (
                  <>
                    <img src={reward.data.source} alt={reward.title} onClick={() => setImageOpen(true)} />
                    <ImageModal open={imageOpen} onClose={() => setImageOpen(false)}>
                      <img src={reward.data.source} alt={reward.title} />
                    </ImageModal>
                  </>
                )}
              </MediaContainer>
            )}
          </RewardContainer>
        ))}
      </RewardsContainer>
    </Container>
  );
}
