import React from "react";
import styled from "@emotion/styled";
import dynamic from "next/dynamic";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReCAPTCHA } from "@livepix/components/common/Captcha";
import MoneyField from "@livepix/components/form/MoneyField";
import { User, UserCurrency } from "@livepix/sdk-js/types/core";
import useAlerts from "@livepix/components/hooks/useAlerts";
import { ProfileData, TipAction } from "@livepix/sdk-js/types/profile";
import ProfileButton from "@livepix/components/profile/ProfileButton";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import MicIcon from "@mui/icons-material/Mic";
import AssistantIcon from "@mui/icons-material/Assistant";
import StopIcon from "@mui/icons-material/Stop";
import Money from "helpers/Money";
import { AudioPlayer } from "./AudioPlayer";
import useWebservice from "@livepix/components/hooks/useWebservice";
import { LoadingButton } from "@mui/lab";
import Link from "next/link";

const MicrophoneWaveform = dynamic(() => import("./MicrophoneWaveform").then((mod) => mod.MicrophoneWaveform), {
  loading: () => <p>Loading...</p>,
});

const FieldContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const MessageOptionsContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;

  > button {
    flex: 1;
    font-size: 16px;
    background: #f4f4f4;
    margin: 0 5px;

    &:hover {
      background: #e8e8e8;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    @media (max-width: 800px) {
      span.MuiButton-startIcon {
        display: none;
      }
    }
  }
`;

const AudioFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  padding: 5px 12px;

  &:hover {
    border-color: #aaa;
  }
`;

const AmountFieldContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
`;

const CustomVoiceControl = styled.div`
  margin-bottom: 20px;
  margin-top: -10px;
  padding: 5px 15px;
  background: #f4f4f4;
  border-radius: 5px;

  span.MuiTypography-root {
    font-size: 16px;
  }
`;

const CurrencySelect = styled(Select)`
  width: 130px;
  height: 60px;
  margin-right: 10px;
`;

const Disclaimer = styled.p`
  margin-top: 10px;
  font-size: 12px;
  color: #666;
  text-align: center;

  a {
    color: #666;
    text-decoration: underline;
  }
`;

type Props = {
  color: string;
  profile?: ProfileData;
  username?: string;
  message?: string;
  currency?: string;
  messageEnabled?: boolean;
  minAmount: number;
  amount?: number;
  suggestedAmount?: number;
  customVoiceEnabled?: boolean;
  minCustomVoiceAmount?: number;
  maxCustomVoiceMessageLength?: number;
  audioEnabled?: boolean;
  minAudioAmount?: number;
  maxAudioDuration?: number;
  currencies: UserCurrency[];
  maxMessageLength: number;
  onTip: TipAction;
  user?: User;
  dummy?: boolean;
  captcha?: ReCAPTCHA;
  onResize: () => void;
};

const toBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || "");
    reader.onerror = (error) => reject(error);
  });

const formatDuration = (seconds: number) => {
  const date = new Date(0);
  date.setSeconds(seconds);
  return date.toISOString().substring(14, 19);
};

export default function TransactionForm({
  user,
  color,
  profile,
  currency,
  messageEnabled,
  minAmount,
  currencies,
  suggestedAmount,
  customVoiceEnabled,
  minCustomVoiceAmount,
  maxCustomVoiceMessageLength,
  maxMessageLength,
  audioEnabled,
  minAudioAmount,
  maxAudioDuration,
  dummy,
  captcha,
  onTip,
  onResize,
  ...props
}: Props) {
  const alerts = useAlerts();
  const webservice = useWebservice();

  const [username, setUsername] = React.useState<string>(props.username || user?.username || "");
  const [message, setMessage] = React.useState<string>(props.message || "");
  const [amount, setAmount] = React.useState<number>(props.amount || 0);
  const [audio, setAudio] = React.useState<Blob>();
  const [audioUrl, setAudioUrl] = React.useState<string>();
  const [selectedCurrencySymbol, setSelectedCurrencySymbol] = React.useState<string>(currency || "BRL");

  const [useCustomVoice, setUseCustomVoice] = React.useState<boolean>(false);

  const [recordDuration, setRecordDuration] = React.useState<number>(0);
  const [isAudioSilent, setAudioSilent] = React.useState<boolean>(false);

  const [recorder, setRecorder] = React.useState<MediaRecorder>();
  const [recording, setRecording] = React.useState<boolean>(false);

  const [generatingMessage, setGeneratingMessage] = React.useState<boolean>(false);

  const [usernameError, setUsernameError] = React.useState<string>();
  const [messageError, setMessageError] = React.useState<string>();
  const [amountError, setAmountError] = React.useState<string>();

  React.useEffect(() => {
    if (user?.username) setUsername(user.username);
  }, [user?.username]);

  React.useEffect(() => {
    if (suggestedAmount !== undefined) setAmount(suggestedAmount);
  }, [suggestedAmount]);

  React.useEffect(() => setAmount(suggestedAmount || 0), [selectedCurrencySymbol]);

  React.useEffect(() => onResize(), [usernameError, message, isAudioSilent]);

  const enabledCurrencies = React.useMemo(() => currencies.filter((c) => c.enabled), [currencies]);

  const selectedCurrency = React.useMemo(
    () => enabledCurrencies.find((c) => c.currency.symbol === selectedCurrencySymbol),
    [enabledCurrencies, selectedCurrencySymbol],
  );

  const effectiveMinAmount = React.useMemo(() => {
    let effectiveMinAmount = minAmount;

    if (audioEnabled && audio) {
      effectiveMinAmount = minAudioAmount || minAmount;
    } else if (useCustomVoice) {
      effectiveMinAmount = minCustomVoiceAmount || minAmount;
    }

    return effectiveMinAmount;
  }, [audio, audioEnabled, minAmount, minAudioAmount, useCustomVoice, minCustomVoiceAmount]);

  const formattedMinimumAmount = React.useMemo(() => {
    return Money.format(effectiveMinAmount, selectedCurrencySymbol);
  }, [selectedCurrency, effectiveMinAmount]);

  const effectiveMaxLength =
    useCustomVoice && maxCustomVoiceMessageLength ? maxCustomVoiceMessageLength : maxMessageLength;

  const stopRecordingTimeout = React.useRef<NodeJS.Timeout>();

  const recordAudio = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      setRecorder(new MediaRecorder(stream));
    });
  };

  const startRecording = (recorder: MediaRecorder) => {
    recorder.start();

    setRecording(true);
    setAudioSilent(false);

    stopRecordingTimeout.current = setTimeout(
      () => {
        stopRecordingTimeout.current = undefined;

        if (recorder.state === "recording") {
          stopRecording(recorder);
        }
      },
      (maxAudioDuration || 30) * 1_000,
    );
  };

  const stopRecording = (recorder: MediaRecorder) => {
    setRecording(false);
    setRecordDuration(0);

    if (stopRecordingTimeout.current) {
      clearTimeout(stopRecordingTimeout.current);
    }

    recorder.stop();

    // Gather the audio data and create the blob
    recorder.ondataavailable = (e) => {
      setAudio(e.data);
      setAudioUrl(URL.createObjectURL(e.data));

      // Check if audio is silent
      const audioContext = new AudioContext();
      const reader = new FileReader();
      reader.readAsArrayBuffer(e.data);

      reader.onload = async (e) => {
        const buffer = await audioContext.decodeAudioData(e.target?.result as ArrayBuffer);
        const data = buffer.getChannelData(0);

        let isSilent = true;
        for (let i = 0; i < data.length; i++) {
          if (Math.abs(data[i]) > 0.02) {
            isSilent = false;
            break;
          }
        }

        setAudioSilent(isSilent);

        await audioContext.close();
      };
    };
  };

  const generateMessage = async () => {
    if (!captcha) {
      alerts.error("Não foi possível carregar o Captcha.");
      return;
    }

    setRecorder(undefined);
    setAudioSilent(false);
    setGeneratingMessage(true);

    try {
      const captchaToken = await captcha.executeAsync();

      const {
        data: { message },
      } = await webservice.post<{
        message: string;
      }>(
        `/profile/${profile?.username}/generate-message`,
        {
          captchaToken,
        },
        { timeout: 30_000 },
      );

      setMessage(message);
    } catch (e: any) {
      alerts.error(e.response?.data?.message || "Não foi possível gerar a mensagem.");
    } finally {
      captcha.reset();
      setGeneratingMessage(false);
    }
  };

  const submit = async () => {
    setUsernameError(undefined);
    setMessageError(undefined);
    setAmountError(undefined);

    if (!/^[A-zÀ-ú0-9 ]{0,20}$/.test(username)) {
      setUsernameError("O nome de usuário deve conter apenas letras, números e espaços e ter no máximo 20 caracteres.");
      return;
    }

    if (username.length === 1) {
      setUsernameError("O nome de usuário deve ter 2 ou mais caracteres.");
      return;
    }

    if (message.length > effectiveMaxLength) {
      setMessageError(`A mensagem deve ter até ${effectiveMaxLength} caracteres.`);
      return;
    }

    if (amount > 100_000) {
      setAmountError("Valor máximo atingido.");
      return;
    }

    if (amount < effectiveMinAmount) {
      setAmountError(`O valor mínimo é de ${formattedMinimumAmount}`);
      return;
    }

    let tipAudio: string | undefined;

    if (audioEnabled && audio) {
      tipAudio = await toBase64(audio);
    }

    onTip(selectedCurrencySymbol, amount, username, message, tipAudio, useCustomVoice)
      .then(() => {
        setAudio(undefined);
        setAudioUrl(undefined);
      })
      .catch((e) => alerts.error(e.response?.data?.message || "Ocorreu um erro inesperado."));
  };

  React.useEffect(() => {
    onResize();

    if (!recorder) {
      setRecordDuration(0);
      setAudio(undefined);
      setAudioUrl(undefined);
      setAudioSilent(false);
    }
  }, [recorder]);

  React.useEffect(() => {
    if (!recording) return;

    setRecordDuration(0);

    const interval = setInterval(() => {
      setRecordDuration((current) => current + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [recording]);

  React.useEffect(() => {
    if (recorder) {
      setUseCustomVoice(false);
    } else if (!messageEnabled && customVoiceEnabled) {
      setUseCustomVoice(true);
    }

    onResize();
  }, [recorder]);

  React.useEffect(() => {
    if (!messageEnabled && !customVoiceEnabled && audioEnabled) {
      recordAudio();
    } else if (!messageEnabled && customVoiceEnabled) {
      setUseCustomVoice(true);
    }
  }, [messageEnabled, audioEnabled, customVoiceEnabled]);

  React.useEffect(() => setUsernameError(undefined), [username]);
  React.useEffect(() => setMessageError(undefined), [message]);
  React.useEffect(() => setAmountError(undefined), [amount]);

  return (
    <>
      <FieldContainer>
        <TextField
          label="Seu nome de usuário"
          variant="outlined"
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          inputProps={{ maxLength: 20 }}
          error={!!usernameError}
          helperText={usernameError}
          fullWidth
        />
      </FieldContainer>
      {effectiveMaxLength > 0 && (
        <>
          {Boolean(messageEnabled || customVoiceEnabled) && (
            <MessageOptionsContainer>
              <LoadingButton
                startIcon={<AssistantIcon />}
                loading={generatingMessage}
                onClick={() => generateMessage()}
              >
                Gerar com IA
              </LoadingButton>
              {audioEnabled && (
                <>
                  {Boolean(recorder) ? (
                    <Button startIcon={<ModeEditOutlineIcon />} onClick={() => setRecorder(undefined)}>
                      Escrever
                    </Button>
                  ) : (
                    <Button startIcon={<MicIcon />} onClick={() => recordAudio()}>
                      Gravar áudio
                    </Button>
                  )}
                </>
              )}
            </MessageOptionsContainer>
          )}
          {Boolean(recorder) ? (
            <>
              <AudioFieldContainer>
                <Box flex="1">
                  {Boolean(audioUrl) ? (
                    <AudioPlayer audio={audioUrl!} />
                  ) : (
                    <Box display="flex">
                      <Box flex="1" marginRight="10px">
                        <MicrophoneWaveform color={color} />
                      </Box>
                      <Typography>
                        {formatDuration(recordDuration)}/{formatDuration(maxAudioDuration || 30)}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box marginLeft="10px">
                  {Boolean(!recording && !audio) && (
                    <Tooltip title="Iniciar gravação">
                      <IconButton color="primary" onClick={() => startRecording(recorder!)}>
                        <MicIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {Boolean(recording) && (
                    <Tooltip title="Parar gravação">
                      <IconButton color="primary" onClick={() => stopRecording(recorder!)}>
                        <StopIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {Boolean(!recording && audio) && (
                    <Tooltip title="Gravar outro">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setAudio(undefined);
                          setAudioUrl(undefined);
                          setRecordDuration(0);
                          setAudioSilent(false);
                        }}
                      >
                        <MicIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </AudioFieldContainer>
              {isAudioSilent && (
                <Box marginTop="-10px" marginBottom="20px">
                  <Alert severity="warning">
                    Não foi detectado som na gravação. Verifique seu microfone e grave novamente.
                  </Alert>
                </Box>
              )}
            </>
          ) : (
            <FieldContainer>
              <TextField
                label="Mensagem"
                variant="outlined"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                inputProps={{
                  maxLength: effectiveMaxLength,
                }}
                error={!!messageError}
                helperText={messageError || `${message.length}/${effectiveMaxLength} caracteres`}
                multiline
                fullWidth
              />
            </FieldContainer>
          )}
        </>
      )}
      {Boolean(messageEnabled && customVoiceEnabled && !recorder) && (
        <CustomVoiceControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={useCustomVoice} size="small" onChange={(_, checked) => setUseCustomVoice(checked)} />
              }
              label="Escolher voz gerada com IA"
            />
          </FormGroup>
        </CustomVoiceControl>
      )}
      <AmountFieldContainer>
        {enabledCurrencies.length > 0 && (
          <CurrencySelect
            value={selectedCurrencySymbol}
            disabled={currencies.length === 0}
            onChange={(e) => setSelectedCurrencySymbol(e.target.value as string)}
            fullWidth
          >
            <MenuItem value="BRL">
              <Box display="flex" alignItems="center">
                <img src={`/images/currencies/brl.png`} alt="BRL" width="20px" />
                <Box marginLeft="10px">R$</Box>
              </Box>
            </MenuItem>
            {enabledCurrencies.map((currency) => (
              <MenuItem key={currency.id} value={currency.currency.symbol}>
                <Box display="flex" alignItems="center">
                  <img
                    src={`/images/currencies/${currency.currency.slug}.png`}
                    alt={currency.currency.symbol}
                    width="20px"
                  />
                  <Box marginLeft="10px">{currency.currency.symbol}</Box>
                </Box>
              </MenuItem>
            ))}
          </CurrencySelect>
        )}
        <MoneyField
          label="Valor"
          value={amount}
          name="amount"
          currency={selectedCurrencySymbol}
          decimals={selectedCurrency?.currency.decimals}
          onChange={(value) => setAmount(value)}
          helperText={amountError || `O valor mínimo é de ${formattedMinimumAmount}`}
          error={!!amountError}
        />
      </AmountFieldContainer>
      <ProfileButton customcolor={color} onClick={() => submit()}>
        {useCustomVoice ? "Escolher Voz" : "Continuar"}
      </ProfileButton>
      <Disclaimer>
        Ao clicar em continuar, você declara que leu e concorda com os{" "}
        <Link href="/institucional/contrato-servico">Termos de Uso</Link> e a{" "}
        <Link href="/institucional/politica-privacidade">Política de Privacidade</Link>.
      </Disclaimer>
    </>
  );
}
