import React from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { Box, Card } from "@mui/material";
import { SubscriptionPlan } from "@livepix/sdk-js/types/core";
import { ProfileData } from "@livepix/sdk-js/types/profile";
import ProfileButton from "@livepix/components/profile/ProfileButton";
import Money from "helpers/Money";

const PlanName = styled.p`
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
`;

const PlanPrice = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 30px;
`;

const PlanDescription = styled.p`
  margin: 10px 0 0;
  font-size: 20px;
`;

const PlanRewardsTitle = styled.p`
  margin: 20px 0 10px;
  text-transform: uppercase;
  font-weight: 500;
  color: #666;
`;

const PlanRewards = styled.div``;

const PlanReward = styled(Card)`
  margin-bottom: 10px;
  padding: 14px;
  background: #f9f9f9;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const RewardType = styled.p`
  margin: 0;
  text-transform: uppercase;
  font-size: 16px;
  color: #666;
`;

const RewardTitle = styled.p`
  margin: 0;
  font-weight: bold;
`;

const RewardDescription = styled.p`
  margin: 0;
`;

const typeLabels: Record<string, string> = {
  "discord-role": "Cargo no Discord",
  "telegram-channel": "Grupo no Telegram",
  "campaign-coupon": "Código patrocinado",
  image: "Imagem",
  video: "Vídeo",
};

type Props = {
  profile: ProfileData;
  plan: SubscriptionPlan;
};

const formatAmount = (amount: number) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(amount);

export default function SubscriptionPlanSlide({ profile, plan }: Props) {
  const router = useRouter();

  return (
    <>
      <PlanName>{plan.name}</PlanName>
      <PlanPrice>{formatAmount(Money.fromCents(plan.amount))}</PlanPrice>
      <PlanDescription>{plan.description}</PlanDescription>
      {Boolean(plan.rewards && plan.rewards.length > 0) && (
        <>
          <PlanRewardsTitle>Recompensas do plano</PlanRewardsTitle>
          <PlanRewards>
            {plan.rewards?.map((reward) => (
              <PlanReward key={reward.id} elevation={0}>
                <RewardType>{typeLabels[reward.type]}</RewardType>
                <RewardTitle>{reward.title}</RewardTitle>
                <RewardDescription>{reward.description}</RewardDescription>
              </PlanReward>
            ))}
          </PlanRewards>
        </>
      )}
      <Box marginTop="20px">
        <ProfileButton
          customcolor={profile.color}
          onClick={() => router.push(`/${profile.username}/assinatura?plan=${plan.id}`)}
        >
          Assinar plano
        </ProfileButton>
      </Box>
    </>
  );
}
